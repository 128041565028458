import React, { useEffect } from "react";
import PostList from "../home/components/PostList";
import ImageGallery from "react-image-gallery";
import { Avatar, Button, Row, Space, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPostPublicByIdThunk } from "../../services/posts/postThunk";
import { useParams } from "react-router-dom";
import { formatTimeToDMYHSMString } from "../../utils/date";

const PostDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { post } = useSelector((state) => state.posts);

  const getPostById = () => {
    if (!id) return;
    dispatch(getPostPublicByIdThunk(id));
  };
  useEffect(() => {
    getPostById();
  }, []);

  const images = [
    {
      original:
        "https://vuonsaigon.vn/wp-content/uploads/2021/12/y-nghia-cua-cac-cay-dao-mai-quat-ngay-tet-3.jpg",
      thumbnail:
        "https://vuonsaigon.vn/wp-content/uploads/2021/12/y-nghia-cua-cac-cay-dao-mai-quat-ngay-tet-3.jpg",
    },
    {
      original:
        "https://xuannong.vn/images/cach-chon-va-cham-soc-trang-tri-cay-quat-dep.jpg",
      thumbnail:
        "https://xuannong.vn/images/cach-chon-va-cham-soc-trang-tri-cay-quat-dep.jpg",
    },
    {
      original:
        "https://vuonsaigon.vn/wp-content/uploads/2021/12/y-nghia-cua-cac-cay-dao-mai-quat-ngay-tet-3.jpg",
      thumbnail:
        "https://vuonsaigon.vn/wp-content/uploads/2021/12/y-nghia-cua-cac-cay-dao-mai-quat-ngay-tet-3.jpg",
    },
  ];
  const weight = post?.post_info?.weight ?? 0;
  const packageNo = post?.post_info?.package_no ?? 0;
  return (
    <>
      <div className="container">
        <div className="container-xxl py-5">
          <div className="row g-0 gx-5">
            <div className="col-lg-8">
              <ImageGallery
                items={images}
                thumbnailHeight="50px"
                showPlayButton={false}
                showFullscreenButton={false}
                renderItem={(item) => (
                  <img
                    src={item.original}
                    alt=""
                    style={{
                      maxHeight: "300px", // Chiều cao tối đa của ảnh chính
                      width: "100%", // Đảm bảo ảnh chiếm đủ chiều ngang
                      objectFit: "cover", // Cắt ảnh nhưng giữ tỷ lệ
                    }}
                  />
                )}
              />
              <Space>
                <Tag color="orange">
                  {!post?.post_info?.is_assigned ? "Chờ kết nối" : "Đã kết nối"}
                </Tag>
                <Tag color="blue">{post?.post_info?.post_type}</Tag>
              </Space>
              <h6 className="mb-2 mt-2">{post?.post_info?.content ?? ""}</h6>
              <small className="mb-2 fst-italic">
                {" "}
                Ngày đăng :{" "}
                {formatTimeToDMYHSMString(post?.post_info?.created_time)}
              </small>

              <p className="mb-2 mt-2">
                <i className="fa fa-solid fa-location-arrow"></i>{" "}
                {post?.post_info?.s_address}
              </p>
              <p className="mb-2 mt-2">
                <i className="fa fa-solid fa-location-arrow"></i>{" "}
                {post?.post_info?.r_address}
              </p>
              <p className="mb-2">
                {" "}
                Thời gian yêu cầu lấy hàng :{" "}
                {formatTimeToDMYHSMString(post?.post_info?.s_pick_time)}
              </p>
              <p className="mb-2">
                {" "}
                Thời gian yêu cầu trả hàng :{" "}
                {formatTimeToDMYHSMString(post?.post_info?.r_return_time)}
              </p>
              <hr />
              <div className="row">
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-4">
                      <p className="flex-fill text-center py-2">
                        <i className="fa fa-solid fa-truck text-primary me-2" />
                        Xe tải
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <p className="flex-fill text-center py-2">
                        <i className="fa fa-solid fa-weight-hanging text-primary me-2" />
                        {weight > 0 ? weight / 1000 : 0} T
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <p className="flex-fill text-center py-2">
                        <i className="fa fa-solid fa-box text-primary me-2" />
                        {`${packageNo} Kiện`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <Space>
                    <Tooltip title="Thêm vào yêu thích">
                      <p className="flex-fill text-center py-2">
                        <i className="fa fa-solid fa-heart text-primary me-2"></i>
                      </p>
                    </Tooltip>
                    <Tooltip title="Chia sẻ">
                      <p className="flex-fill text-center py-2">
                        <i className="fa fa-solid fa-share text-primary me-2"></i>
                      </p>
                    </Tooltip>
                  </Space>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="row border p-2 text-center rounded">
                <Avatar size={50} />
                <h6 className="mb-2">Nguyen Van A</h6>
                <a className="mb-2">Xem thêm tin đăng</a>
                <Button block className="mb-2" color="orange" variant="solid">
                  0936**** Hiện số
                </Button>
                <Button block type="primary" ghost className="mb-2">
                  Chat qua Zalo
                </Button>
                <Button block type="primary" ghost className="mb-2">
                  Yêu cầu nhận chuyến
                </Button>
              </div>
              <div
                className="row mt-2 p-2 rounded"
                style={{ background: "#F2F2F2" }}
              >
                {" "}
                <h6>Xe tìm hàng</h6>
                <Space direction="vertical">
                  <a>Hàng Container</a>
                  <a>Hàng Kiện</a>
                  <a>Hàng Siêu trường</a>
                  <a>Hàng Lẻ</a>
                  <a>Hàng Khác</a>
                </Space>
                <h6 className="mt-2">Hàng tìm xe</h6>
                <Space direction="vertical">
                  <a>Xe Container</a>
                  <a>Xe tải</a>
                  <a>Xe con</a>
                  <a>Xe máy</a>
                </Space>
              </div>
              {/* <div className="mt-2">
                <img src={require("../../assets/images/banner1.gif")} alt="" />
              </div> */}
            </div>
            <div className="col-lg-1"></div>
          </div>
          <h5 className="mt-4">Bài đăng dành cho bạn</h5>
          <PostList postTypeId={2} isShowHeader={false} perPage={4} />
        </div>
      </div>
    </>
  );
};

export default PostDetail;
