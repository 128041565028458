import React from "react";

const Search = () => {
  return (
    <div
      className="container-fluid bg-primary mb-5 wow fadeIn"
      data-wow-delay="0.1s"
      style={{ padding: 35 }}
    >
      <div className="container">
        <div className="row g-2">
          <div className="col-md-10">
            <div className="row g-2">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control border-0 py-3"
                  placeholder="Từ khoá"
                />
              </div>
              <div className="col-md-4">
                <select className="form-select border-0 py-3">
                  <option selected="">Dịch vụ</option>
                  <option value={1}>Thuê xe</option>
                  <option value={2}>Xe tìm hàng</option>
                </select>
              </div>
              <div className="col-md-4">
                <select className="form-select border-0 py-3">
                  <option selected="">Khu vực</option>
                  <option value={1}>Toàn quốc</option>
                  <option value={2}>Hà Nội</option>
                  <option value={3}>Hải Phòng 3</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <button className="btn btn-dark border-0 w-100 py-3">
              Tìm kiếm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
