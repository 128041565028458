const LOCAL_STORAGE_KEY = {
  MENUS: "menu",
  USER_INFO: "user",
  COMPANY_INFO: "company",
};

const setLocalStorageItem = (name, value) => {
  localStorage.setItem(name, value);
};

const getLocalStorageItem = (name) => {
  if (!name) return null;
  return localStorage.getItem(name);
};

export const deleteLocalStorageItem = (name) => {
  localStorage.removeItem(name);
};

export const removeAllLocalStorage = () => localStorage.clear();

export const setMenuItems = (menus) => {
  setLocalStorageItem(LOCAL_STORAGE_KEY.MENUS, JSON.stringify(menus));
};

export const getMenuItems = () => {
  return JSON.parse(getLocalStorageItem(LOCAL_STORAGE_KEY.MENUS));
};

export const setUserInfoItem = (user) => {
  if (!user) return;
  delete user?.password;
  const userString = JSON.stringify(user);
  // const userEnCode = btoa(userString);
  setLocalStorageItem(LOCAL_STORAGE_KEY.USER_INFO, userString);
};

export const getUserInfoItem = () => {
  const user = getLocalStorageItem(LOCAL_STORAGE_KEY.USER_INFO);
  // const userDecode = atob(user);
  const userObject = JSON.parse(user);
  return userObject;
};

export const setCompanyInfoItem = (company) => {
  if (!company) return;
  const companyString = JSON.stringify(company);
  setLocalStorageItem(LOCAL_STORAGE_KEY.COMPANY_INFO, companyString);
};

export const getCompanyInfoItem = () => {
  const company = getLocalStorageItem(LOCAL_STORAGE_KEY.COMPANY_INFO);
  const companyObject = JSON.parse(company);
  return companyObject;
};
