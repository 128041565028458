import { Button, Checkbox, Col, Form, Input, Row, Typography } from "antd";
import React from "react";
import { AUTH_SERVICES } from "../../services/auth";
import { setAccessToken } from "../../utils/cookieUtils";
import { setUserInfoItem } from "../../utils/localStorageUtils";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const onSubmitForm = (v) => {
    AUTH_SERVICES.login(v, (res) => {
      console.log("res", res);
      if (res?.data?.isSuccess) {
        setAccessToken(res?.data?.accessToken, {
          expired: res?.data?.expiresIn,
        });
        setUserInfoItem(res?.data?.userInfo);
        navigate("/");
      }
    });
  };

  return (
    <div
      style={{
        backgroundImage:
          "url('https://img.freepik.com/free-vector/hand-drawn-illustration-international-trade_23-2149163921.jpg?t=st=1736923864~exp=1736927464~hmac=47867a5a59b2efbbcf703adf5956d5b018bfa9baffac0e8f48427d7778ba0865&w=740')", // Thay bằng link ảnh logistic thực tế
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div
              className="card shadow-lg"
              style={{ borderRadius: "15px", border: "none" }}
            >
              <div className="card-body p-4">
                <h3 className="text-center mb-4">Đăng nhập</h3>
                <Form
                  name="login-form"
                  layout="vertical"
                  onFinish={onSubmitForm}
                >
                  <Form.Item label="Số điện thoại" name={"username"}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Mật khẩu" name={"password"}>
                    <Input.Password />
                  </Form.Item>
                  <Row justify={"space-between"}>
                    <Col>
                      <Form.Item name={"remember"}>
                        <Checkbox>Nhớ mật khẩu</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Typography.Link href="/forget-password">
                        Quên mật khẩu
                      </Typography.Link>
                    </Col>
                  </Row>

                  <div className="d-grid">
                    <Button block type="primary" htmlType="submit">
                      Đăng nhập
                    </Button>
                  </div>
                </Form>
                <div className="text-center mt-3 text-primary">
                  <small>
                    Bạn chưa có tài khoản ?{" "}
                    <a href="#" className="text-primary">
                      Đăng ký
                    </a>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
