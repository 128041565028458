import { createAsyncThunk } from "@reduxjs/toolkit";
import httpClient from "../httpRequest";

export async function getPublicPosts(params) {
  try {
    return httpClient.get("/posts/public", { params });
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function getPublicPostById(id) {
  try {
    return httpClient.get(`/posts/public/${id}`);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const getPostPublicThunk = createAsyncThunk(
  "posts/list-public",
  async (params, thunkAPI) => {
    try {
      const response = await getPublicPosts(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getPostPublicByIdThunk = createAsyncThunk(
  "posts/list-public-by-id",
  async (id, thunkAPI) => {
    try {
      const response = await getPublicPostById(id);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
