import { message } from "antd";
import React from "react";

const pushWarningMess = (mess = "Chú ý") => {
  message.warning(mess);
};
const pushInfoMess = (mess = "Thông tin") => {
  message.info(mess);
};
const pushErrorMess = (mess = "Có lỗi xảy ra, vui lòng thử lại !") => {
  message.error(mess);
};

const pushSuccessMess = (mess = "Thành công !") => {
  message.success(mess);
};

export const NOTIFICATION_SERVICES = {
  pushErrorMess,
  pushInfoMess,
  pushSuccessMess,
  pushWarningMess,
};
