import React from "react";
import { getUserInfoItem } from "../../utils/localStorageUtils";
import { getAccessToken } from "../../utils/cookieUtils";

const Navbar = () => {
  const userInfo = getUserInfoItem();
  const token = getAccessToken();
  return (
    <div className="container-fluid nav-bar bg-transparent">
      <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
        <a
          href="/"
          className="navbar-brand d-flex align-items-center text-center"
        >
          <div className="icon p-2 me-2">
            <img
              className="img-fluid"
              src={require("../../assets/images/icon-deal.png")}
              alt="Icon"
              style={{ width: 30, height: 30 }}
            />
          </div>
          <h1 className="m-0 text-primary">ShipMaster</h1>
        </a>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto">
            <a href="/" className="nav-item nav-link active">
              Trang chủ
            </a>
            <a href="/" className="nav-item nav-link">
              Tính cước
            </a>
            <a href="/post/create" className="nav-item nav-link">
              Đăng tin miễn phí
            </a>
            {token ? (
              <>
                <div class="nav-item dropdown">
                  <a
                    href="#"
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    {userInfo?.full_name}
                  </a>
                  <div class="dropdown-menu rounded-0 m-0">
                    <a href="/acc/posts" class="dropdown-item">
                      Quản lý tin đăng
                    </a>
                    <a href="/acc/change-password" class="dropdown-item">
                      Đổi mật khẩu
                    </a>
                    <a href="/" class="dropdown-item">
                      Đăng xuất
                    </a>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          {!token ? (
            <a
              href="/sign-in"
              className="btn btn-primary px-3 d-none d-lg-flex"
            >
              Đăng ký / Đăng nhập
            </a>
          ) : null}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
