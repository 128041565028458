const COOKIE_KEYS = {
  ACCESS_TOKEN: "access_token",
  USER_INFO: "user_info",
};

export function setCookie(name, value, time) {
  var expires = "";
  var date = new Date();
  if (time?.days) {
    date.setTime(date.getTime() + date * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }

  if (time?.expired) {
    date.setTime(date.getTime() + time?.expired * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    deleteCookie(name);
  }
}

export const setAccessToken = (token, expired) => {
  setCookie(COOKIE_KEYS.ACCESS_TOKEN, token, expired);
};

export const getAccessToken = () => {
  return getCookie(COOKIE_KEYS.ACCESS_TOKEN);
};
