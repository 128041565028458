/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import PublicLayout from "./components/layouts/Public";
import Login from "./pages/auth/Login";
import PostDetail from "./pages/postDetail";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/post-detail/:id" element={<PostDetail />} />
        </Route>
        <Route path="/sign-in" element={<Login />} />

        {/* Route cho 404 */}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
