import React from "react";
import PublicHeader from "./components/PublicHeader";
import Search from "./components/Search";
import SearchByCategory from "./components/SearchByCategory";
import PostList from "./components/PostList";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  autoplay: true,
};

const Home = () => {
  return (
    <>
      <PublicHeader />
      <Search />
      <SearchByCategory />
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img position-relative overflow-hidden p-5 pe-0">
                <img
                  className="img-fluid w-100"
                  src={require("../../assets/images/route.jpg")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">Lộ trình hoàn hảo với Shipmaster</h1>

              <p>
                <i className="fa fa-check text-primary me-3" />
                Tối ưu hóa vận chuyển: Xe có thể tìm được hàng phù hợp để vận
                chuyển ngay cả khi xe trống, giảm thời gian di chuyển không có
                tải.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                Tiết kiệm chi phí: Chủ xe và chủ hàng tiết kiệm chi phí nhờ tận
                dụng tối đa công suất vận chuyển.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                Giảm chi phí trung gian: Loại bỏ hoặc giảm sự phụ thuộc vào môi
                giới, giúp chi phí cạnh tranh hơn.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                Danh sách xe và hàng phong phú: Cung cấp nhiều loại xe (xe tải,
                xe container, xe đông lạnh,...) và hàng hóa để lựa chọn.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                Kết nối nhanh chóng: Chủ xe và chủ hàng có thể kết nối trực tiếp
                thông qua thông tin liên hệ hoặc hệ thống chat tích hợp.
              </p>
              {/* <a className="btn btn-primary py-3 px-5 mt-3" href="">
                Read More
              </a> */}
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Property List Start */}
      <PostList />
      {/* Property List End */}
      {/* Call to Action Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="bg-light rounded p-3">
            <div
              className="bg-white rounded p-4"
              style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
            >
              <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                  <img
                    className="img-fluid rounded w-100"
                    src={require("../../assets/images/partner.jpg")}
                    alt=""
                  />
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                  <div className="mb-4">
                    <h1 className="mb-3">
                      Đăng ký để trở thành đối tác của chúng tôi
                    </h1>
                    <p>
                      Hãy cùng chúng tôi xây dựng một hệ sinh thái vận tải thông
                      minh và hiệu quả hơn!
                    </p>
                  </div>
                  <a href="" className="btn btn-primary py-3 px-4 me-2">
                    <i className="fa fa-phone-alt me-2" />
                    Liên hệ
                  </a>
                  {/* <a href="" className="btn btn-dark py-3 px-4">
                    <i className="fa fa-calendar-alt me-2" />
                    Điều khoản &amp; Bảo mật
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Call to Action End */}
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 600 }}
          >
            <h1 className="mb-3">Tư vấn giao nhận</h1>
            <p>
              Chuyên viên tư vấn tận tâm, giàu kinh nghiệm sẽ hỗ trợ bạn trong
              suốt quá trình giao hàng.
            </p>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/team-1.jpg")}
                    alt=""
                  />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Tuyết Mai</h5>
                  <small>TVGN</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/team-1.jpg")}
                    alt=""
                  />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Trương Thị Sen</h5>
                  <small>TVGN</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/team-1.jpg")}
                    alt=""
                  />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Phương Nhi</h5>
                  <small>TVGN</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/team-1.jpg")}
                    alt=""
                  />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Văn Hoàng</h5>
                  <small>TVGN</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}
      {/* Testimonial Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 600 }}
          >
            <h1 className="mb-3">Khách hàng nói về chúng tôi</h1>
            <p>
              ShipMaster là cầu nối giữa Khách hàng, tư vấn giao nhận và các đơn
              vị vận chuyển. Sự hài lòng của Quý khách là thành công của chúng
              tôi.
            </p>
          </div>
          <div
            className="testimonial-carousel wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <Slider {...settings}>
              <div className="testimonial-item bg-light rounded p-3">
                <div className="bg-white border rounded p-4">
                  <p>
                    Chúng tôi đánh giá cao dịch vụ giao hàng nhanh chóng, nhân
                    viên cẩn thận, tận tâm. Những vấn đề phát sinh trong quá
                    trình giao nhận đều được thông báo và giải quyết kịp thời
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      className="img-fluid flex-shrink-0 rounded"
                      src={require("../../assets/images/testimonial-1.jpg")}
                      style={{ width: 45, height: 45 }}
                      alt=""
                    />
                    <div className="ps-3">
                      <h6 className="fw-bold mb-1">Vivian</h6>
                      <small>Decathon</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-item bg-light rounded p-3">
                <div className="bg-white border rounded p-4">
                  <p>
                    Các dịch vụ hỗ trợ trong suốt quá trình giao nhận rất tốt.
                    Chúng tôi cảm nhận được sự chuyên nghiệp, thân thiện và phản
                    hồi nhanh chóng từ đội ngũ Tư vấn giao nhận
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      className="img-fluid flex-shrink-0 rounded"
                      src={require("../../assets/images/testimonial-3.jpg")}
                      style={{ width: 45, height: 45 }}
                    />
                    <div className="ps-3">
                      <h6 className="fw-bold mb-1">Ms Trang</h6>
                      <small>Nội thất</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-item bg-light rounded p-3">
                <div className="bg-white border rounded p-4">
                  <p>
                    Dịch vụ giao nhận dễ sử dụng, giúp người dùng dễ dàng theo
                    dõi đơn hàng. Dịch vụ giải quyết sự cố khi nhầm hàng, sai
                    địa chỉ rất nhanh chóng, hiệu quả.
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      className="img-fluid flex-shrink-0 rounded"
                      src={require("../../assets/images/testimonial-3.jpg")}
                      style={{ width: 45, height: 45 }}
                    />
                    <div className="ps-3">
                      <h6 className="fw-bold mb-1">Ms Quynh</h6>
                      <small>ACFC</small>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
