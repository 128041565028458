import { createSlice } from "@reduxjs/toolkit";
import { getPostPublicByIdThunk, getPostPublicThunk } from "./postThunk";

const initialState = {
  posts: [],
  post: {},
};

const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getPostPublicThunk.fulfilled, (state, action) => {
      const { payload } = action;
      const data = payload?.data?.data ?? [];
      state.posts = data;
    });
    builder.addCase(getPostPublicByIdThunk.fulfilled, (state, action) => {
      const { payload } = action;
      console.log("🚀 ~ builder.addCase ~ payload:", payload);
      const data = payload?.data?.data ?? {};
      state.post = data;
    });
  },
});

export default postsSlice.reducer;
