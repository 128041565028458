import React from "react";

const SearchByCategory = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: 600 }}
        >
          <h1 className="mb-3">Xe tìm hàng, hàng tìm xe</h1>
          <p>Đợi hàng đến ngủ mơ, đã có Shipmaster</p>
        </div>
        <div className="row g-4">
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <a
              className="cat-item d-block bg-light text-center rounded p-3"
              href=""
            >
              <div className="rounded p-4">
                <div className="icon mb-3">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/srv_container_vehicle_icon.png")}
                    alt="Icon"
                  />
                </div>
                <h6>Thuê xe Container</h6>
                <span>90 Tin đăng</span>
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <a
              className="cat-item d-block bg-light text-center rounded p-3"
              href=""
            >
              <div className="rounded p-4">
                <div className="icon mb-3">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/truck_icon.png")}
                    alt="Icon"
                  />
                </div>
                <h6>Thuê xe tải</h6>
                <span>90 Tin đăng</span>
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <a
              className="cat-item d-block bg-light text-center rounded p-3"
              href=""
            >
              <div className="rounded p-4">
                <div className="icon mb-3">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/car_icon.png")}
                    alt="Icon"
                  />
                </div>
                <h6>Xe con</h6>
                <span>90 Tin đăng</span>
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <a
              className="cat-item d-block bg-light text-center rounded p-3"
              href=""
            >
              <div className="rounded p-4">
                <div className="icon mb-3">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/motobike_icon.png")}
                    alt="Icon"
                  />
                </div>
                <h6>Xe máy</h6>
                <span>90 Tin đăng</span>
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <a
              className="cat-item d-block bg-light text-center rounded p-3"
              href=""
            >
              <div className="rounded p-4">
                <div className="icon mb-3">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/container.png")}
                    alt="Icon"
                  />
                </div>
                <h6>Hàng Container</h6>
                <span>90 Tin đăng</span>
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <a
              className="cat-item d-block bg-light text-center rounded p-3"
              href=""
            >
              <div className="rounded p-4">
                <div className="icon mb-3">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/package_icon.png")}
                    alt="Icon"
                  />
                </div>
                <h6>Hàng kiện</h6>
                <span>90 Tin đăng</span>
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <a
              className="cat-item d-block bg-light text-center rounded p-3"
              href=""
            >
              <div className="rounded p-4">
                <div className="icon mb-3">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/icon-condominium.png")}
                    alt="Icon"
                  />
                </div>
                <h6>Siêu trường</h6>
                <span>90 Tin đăng</span>
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <a
              className="cat-item d-block bg-light text-center rounded p-3"
              href=""
            >
              <div className="rounded p-4">
                <div className="icon mb-3">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/other_icon.png")}
                    alt="Icon"
                  />
                </div>
                <h6>Khác</h6>
                <span>90 Tin đăng</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchByCategory;
