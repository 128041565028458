import React, { useEffect } from "react";
import img1 from "../../../assets/images/carousel-2.jpg";
import img2 from "../../../assets/images/carousel-1.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PublicHeader = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div className="container-fluid header bg-white p-0">
      <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
        <div className="col-md-6 p-5 mt-lg-5">
          <h1 className="display-5 animated fadeIn mb-4">
            Chờ hàng đến <span className="text-primary">ngủ mơ </span>
            đừng lo đã có Shipmaster
          </h1>
          <p className="animated fadeIn mb-4 pb-2">
            Với nền tảng kết nối hàng tìm xe, xe tìm hàng, dịch vụ của chúng tôi
            giúp tiết kiệm thời gian, giảm chi phí vận chuyển và nâng cao hiệu
            quả logistics. Khách hàng có thể nhanh chóng tìm được đối tác vận
            chuyển đáng tin cậy, trong khi các tài xế cũng dễ dàng tiếp cận
            nguồn hàng cần vận chuyển, tạo ra sự hài hòa và thuận lợi cho cả hai
            bên.
          </p>
          <a href="" className="btn btn-primary py-3 px-5 me-3 animated fadeIn">
            Bắt đầu ngay
          </a>
        </div>
        <div className="col-md-6 animated fadeIn">
          <div className="header-carousel">
            <Slider {...settings}>
              <div>
                <img className="img-fluid" src={img1} alt="" />
              </div>
              <div>
                <img className="img-fluid" src={img2} alt="" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicHeader;
