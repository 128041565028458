import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostPublicThunk } from "../../../services/posts/postThunk";
import { Tooltip, Typography } from "antd";
import { truncateText } from "../../../utils/utils";

const PostList = ({ postTypeId = null, isShowHeader = true, perPage = 6 }) => {
  const dispatch = useDispatch();
  const { posts } = useSelector((state) => state.posts);
  const [searchParams, setParams] = useState({
    page: 1,
    perPage,
    post_type_id: postTypeId,
  });
  const getPosts = (searchParams) => {
    dispatch(getPostPublicThunk(searchParams));
  };

  useEffect(() => {
    getPosts(searchParams);
  }, []);

  const handleChangeTab = (tabIndex) => {
    const newParams = { ...searchParams, post_type_id: tabIndex };
    setParams(newParams);
    setTimeout(() => {
      getPosts(newParams);
    }, 500);
  };
  let durationTime = 0.1;
  return (
    <div className="container-xxl py-5">
      <div className="container">
        {isShowHeader ? (
          <div className="row g-0 gx-5 align-items-end">
            <div className="col-lg-6">
              <div
                className="text-start mx-auto mb-5 wow slideInLeft"
                data-wow-delay="0.1s"
              >
                <h1 className="mb-3">Bắt đầu ngay thôi</h1>
                <p>
                  Tối ưu hoá hành trình chi phí vận chuyển của bạn, tìm đối tác
                  vận chuyển nhanh chóng
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 text-start text-lg-end wow slideInRight"
              data-wow-delay="0.1s"
            >
              <ul className="nav nav-pills d-inline-flex justify-content-end mb-5">
                <li className="nav-item me-2">
                  <a
                    className="btn btn-outline-primary active"
                    data-bs-toggle="pill"
                    href="#tab-1"
                    onClick={() => handleChangeTab(null)}
                  >
                    Tất cả
                  </a>
                </li>
                <li className="nav-item me-2">
                  <a
                    className="btn btn-outline-primary"
                    data-bs-toggle="pill"
                    href="#tab-2"
                    onClick={() => handleChangeTab(1)}
                  >
                    Xe tìm hàng
                  </a>
                </li>
                <li className="nav-item me-0">
                  <a
                    className="btn btn-outline-primary"
                    data-bs-toggle="pill"
                    href="#tab-3"
                    onClick={() => handleChangeTab(2)}
                  >
                    Hàng tìm xe
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        <div className="tab-content">
          {[1, 2, 3].map((tab, tabIndex) => {
            return (
              <div
                id={`tab-${tab}`}
                className={`tab-pane fade show p-0 ${
                  tabIndex === 0 ? "active" : ""
                }`}
              >
                <div className="row g-4">
                  {posts.map((el, i) => {
                    durationTime = i % 3 === 0 ? 0.1 : durationTime + 0.2;
                    const weight = el?.weight ?? 0;
                    const packageNo = el?.package_no ?? 0;
                    return (
                      <div
                        className="col-lg-3 col-md-6 wow fadeInUp"
                        data-wow-delay={`${durationTime}s`}
                      >
                        <div className="property-item rounded overflow-hidden">
                          <div className="position-relative overflow-hidden">
                            <img
                              className="img-fluid"
                              src={require("../../../assets/images/goodneedcar.jpg")}
                              alt=""
                            />
                            <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                              {el?.post_type}
                            </div>
                            <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                              {!el?.is_assigned ? "Chờ kết nối" : "Đã kết nối"}
                            </div>
                          </div>
                          <div className="p-4 pb-0">
                            {/* <h5 class="text-primary mb-3">$12,345</h5> */}
                            <a href={`/post-detail/${el?._id}`}>
                              <Tooltip title={el?.content}>
                                {truncateText(el?.content, 50)}
                              </Tooltip>
                            </a>
                            {/* <p>
                              <i className="fa fa-map-marker-alt text-primary me-2" />
                              {el?.s_address}
                            </p>
                            <p>
                              <i className="fa fa-map-marker-alt text-primary me-2" />
                              {el?.r_address ?? "?"}
                            </p> */}
                            <p>
                              <i className="fa fa-solid fa-clock text-primary me-2 font-italics" />
                              3 giờ trước
                            </p>
                          </div>
                          <div className="d-flex border-top">
                            <small className="flex-fill text-center border-end py-2">
                              <i className="fa fa-solid fa-truck text-primary me-2" />
                              Xe tải
                            </small>
                            <small className="flex-fill text-center border-end py-2">
                              <i className="fa fa-solid fa-weight-hanging text-primary me-2" />
                              {weight > 0 ? weight / 1000 : 0} T
                            </small>
                            <small className="flex-fill text-center py-2">
                              <i className="fa fa-solid fa-box text-primary me-2" />
                              {`${packageNo} Kiện`}
                            </small>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {posts?.length > perPage ? (
                    <div
                      className="col-12 text-center wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      <a className="btn btn-primary py-2" href="">
                        Xem thêm
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PostList;
