import { Select } from "antd";
import React from "react";
// import CryptoJS from "crypto-js";
const { Option } = Select;

export const isNull = (data) => {
  return data === undefined || data === null;
};

export const isTrue = (data) => {
  return data === "true" || data === true;
};

export const isEmptyArray = (data) => {
  return Array.isArray(data) && data.length === 0;
};

export const isEmptyString = (data) => {
  return typeof data === "string" && data.length === 0;
};

export const isEmptyObject = (data) => {
  return typeof data === "object" && Object.keys(data).length === 0;
};

export const isNullOrEmpty = (data) => {
  let result = false;
  const dataIsNull = isNull(data);
  if (dataIsNull) {
    result = true;
  } else {
    const dataIsEmptyArray = isEmptyArray(data);
    const dataIsEmptyObject = isEmptyObject(data);
    const dataIsEmptyString = isEmptyString(data);
    if (dataIsEmptyArray || dataIsEmptyObject || dataIsEmptyString) {
      result = true;
    }
  }
  return result;
};

export function normalizeToOptions(items, labelField) {
  if (isNullOrEmpty(items)) {
    return [];
  }
  const result = items.map((item) => normalizeToOption(item, labelField));
  return result;
}

export function normalizeToOption(item, labelField) {
  if (isNullOrEmpty(item)) {
    return undefined;
  }
  let label = null;
  if (typeof labelField === "function") {
    label = labelField(item);
  } else {
    label = item[labelField];
  }
  const value = { ...item, value: item._id + "", label };
  return value;
}

export const getOptionsSelect = (options) => {
  if (!options) return [];
  return options.map((el, i) => (
    <Option value={el.value} key={i}>
      {el.label}
    </Option>
  ));
};

export const toLowerCaseNonAccent = (input) => {
  let str = input?.toLowerCase();
  str = str?.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str?.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str?.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str?.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str?.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str?.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str?.replace(/đ/g, "d");
  str = str?.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
  str = str?.replace(/\u02C6|\u0306|\u031B/g, "");
  return str;
};

export const returnCountStar = ({
  one = 0,
  two = 0,
  three = 0,
  four = 0,
  five = 0,
}) => {
  const reviews = one + two + three + four + five;

  const countRating =
    reviews === 0
      ? 0
      : (one * 1 + two * 2 + three * 3 + four * 4 + five * 5) / reviews;

  const data = {
    countRating: +countRating?.toFixed(1),
    countReviews: reviews,
  };
  return data;
};

export const getSelectValues = (values) => {
  const isObj = typeof values === "object";
  if (isObj) {
    return values?.value;
  }
  return values;
};

export const getLabelPlanOptions = (plan) => {
  const { plan_code } = plan;
  const vehicle = plan?.vehicle_id?.plate_no;
  const driver = plan?.driver_id?.full_name ?? "_";
  return [vehicle, driver, plan_code].join(" / ");
};

export const getProvinceOptions = (provinces) => {
  if (isNullOrEmpty(provinces)) return [];
  const results = provinces?.map((el) => {
    return {
      value: el?.province_id,
      label: el?.name,
    };
  });
  return results;
};

export const isTheSameVehicle = (vehicle1, vehicle2) => {
  if (!vehicle1 || !vehicle2) return false;
  const vehicle1Str = vehicle1.replace(/[.,-]/g, "");
  const vehicle2Str = vehicle2.replace(/[.,-]/g, "");
  return vehicle1Str === vehicle2Str;
};

// function normalizeKey(key) {
//   return CryptoJS.enc.Utf8.parse(key.padEnd(16, " ")); // Chuẩn hóa khóa dài 16 byte
// }

// const secretKey = "boX@!2024*&$128/";
// export function decryptWithCryptoJS(encryptedText) {
//   if (!encryptedText) return "";
//   const encryptedData = CryptoJS.enc.Base64.parse(encryptedText); // Giải mã Base64
//   const ivLength = 16;
//   const iv = CryptoJS.lib.WordArray.create(
//     encryptedData.words.slice(0, ivLength / 4)
//   );
//   const ciphertext = CryptoJS.lib.WordArray.create(
//     encryptedData.words.slice(ivLength / 4)
//   );

//   const normalizedKey = normalizeKey(secretKey);

//   const decrypted = CryptoJS.AES.decrypt(
//     { ciphertext: ciphertext },
//     normalizedKey,
//     {
//       iv: iv,
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.Pkcs7,
//     }
//   );
//   const result = decrypted.toString(CryptoJS.enc.Utf8); // Trả về dữ liệu giải mã
//   return result;
// }

export function maskString(input) {
  if (!input || input?.length < 6) {
    return "****";
  }

  const firstPart = input.slice(0, 2);
  const lastPart = input.slice(-2);
  const maskedPart = "*".repeat(input.length - 4);

  return `${firstPart}${maskedPart}${lastPart}`;
}

// export const displayPhoneNumber = (number) =>
//   maskString(decryptWithCryptoJS(number));

export const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};
