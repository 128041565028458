import React from "react";
import Navbar from "../ui/Navbar";
import Footer from "../ui/Footer.js";
import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <div className="container-xxl bg-white p-0">
      {/* Spinner Start */}
      {/* <div
        id="spinner"
        className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div> */}
      {/* Spinner End */}
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up" />
      </a>
    </div>
  );
};

export default PublicLayout;
