import dayjs from "dayjs";
export const formatTimeYYYY_MM_DD = "YYYY-MM-DD";
export const formatTimeDDMMYYY = "DD/MM/YYYY";
export const formatTimeDD_MM = "DD/MM";
export const formatTimeToDMYString = (time) => {
  if (!time) return "";
  return dayjs(time).format("DD/MM/YYYY");
};

export const formatTimeToDMYHSMString = (time) => {
  if (!time) return "";
  return dayjs(time).format("DD/MM/YYYY HH:mm:ss");
};

export const formatTimeToDMYHSMtring = (time) => {
  if (!time) return "";
  return dayjs(time).format("DD/MM/YYYY HH:mm");
};

export const formatDateYYYYMMDDString = (
  date,
  format = formatTimeYYYY_MM_DD
) => {
  if (!date) return "";
  return dayjs(date).format(format);
};

export const formatDate = (date, format = formatTimeYYYY_MM_DD) => {
  if (!date) return "";
  return dayjs(date).format(format);
};

export const initTimeDMYHSMString = () => {
  return dayjs().format("DDMMYYHHSSmm");
};

export const initDateWithFormat = (format = formatTimeYYYY_MM_DD) => {
  return dayjs().format(format);
};

export const initDateForFormInput = (date) => {
  const newDate = date
    ? dayjs(date).format("YYYY-MM-DD")
    : dayjs().format("YYYY-MM-DD");
  return dayjs(newDate);
};

export const initTimeForFormInput = () => {
  const newTime = dayjs().format("HH:mm:ss");
  return dayjs(newTime, "HH:mm:ss");
};

export const initTimeDD_MM = () => {
  return dayjs().format("DD/MM");
};
export const initTimeDD_MM_Tomorrow = () => {
  return dayjs().add(1, "days").format("DD/MM");
};

export const subtractDate = (day, format = formatTimeYYYY_MM_DD) => {
  return dayjs().subtract(day, "days").format(format);
};

export const fromToNowString = (day, format = formatTimeDDMMYYY) => {
  return `${dayjs().subtract(day, "days").format(format)} - ${dayjs().format(
    format
  )}`;
};

export const isToDay = (day, format = formatTimeYYYY_MM_DD) => {
  return dayjs().isSame(dayjs(day).format(format), "day");
};
