import httpClient from "./httpRequest";
import { NOTIFICATION_SERVICES } from "./messageSrv";

export const httpGet = (url, params, successFunc, errFunc) => {
  httpClient
    .get(url, { params })
    .then((res) => {
      successFunc(res);
    })
    .catch((err) => {
      NOTIFICATION_SERVICES.pushErrorMess(err?.response?.data?.mess ?? null);
      if (errFunc) errFunc();
    });
};
export const httpPost = (url, payload, successFunc, errFunc) => {
  httpClient
    .post(url, payload)
    .then((res) => {
      successFunc(res);
      NOTIFICATION_SERVICES.pushSuccessMess();
    })
    .catch((err) => {
      NOTIFICATION_SERVICES.pushErrorMess(err?.response?.data?.mess ?? null);
      if (errFunc) errFunc();
    });
};

export const httpPut = (url, payload, successFunc, errFunc) => {
  httpClient
    .put(url, payload)
    .then((res) => {
      successFunc(res);
      NOTIFICATION_SERVICES.pushSuccessMess();
    })
    .catch((err) => {
      NOTIFICATION_SERVICES.pushErrorMess(err?.response?.data?.mess ?? null);
      if (errFunc) errFunc();
    });
};
export const httpDelete = (url, successFunc, errFunc) => {
  httpClient
    .delete(url)
    .then((res) => {
      successFunc(res);
      NOTIFICATION_SERVICES.pushSuccessMess();
    })
    .catch((err) => {
      NOTIFICATION_SERVICES.pushErrorMess();
      if (errFunc) errFunc(err?.response?.data?.mess ?? null);
    });
};

export function exportExcel(endpoint, params, file_name = "Report") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  //
  httpClient
    .get(endpoint, { responseType: "blob", params: params })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        file_name + "_" + date + month + year + ".xlsx"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      NOTIFICATION_SERVICES.pushErrorMess(
        "Có lỗi trong quá trình xử lý DL. Vui lòng thử lại sau!"
      );
    });
}

export function downloadTemplateSrv(fileName) {
  httpClient
    .get("/files/download-template", {
      responseType: "blob",
      params: { file_name: fileName },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      NOTIFICATION_SERVICES.pushErrorMess("Vui lòng thử lại sau !");
    });
}
