import axios from "axios";
import { deleteAllCookies, getAccessToken } from "../utils/cookieUtils";

const httpClient = axios.create({
  baseURL: `https://shipws.logsmaster.com.vn/api`,
  // baseURL: `http://localhost:5012/api`,
  // baseURL: `${process.env.REACT_APP_HOST_URL}/api`,
});
if (getAccessToken)
  httpClient.defaults.headers.common["Authorization"] = getAccessToken();

httpClient.interceptors.request.use((request) => {
  return request;
});

httpClient.interceptors.response.use(
  function (response) {
    // console.log('response', response);
    return response;
  },
  function (error) {
    console.log("reponse error", error, error.response);
    // const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401
      // !originalRequest._retry
    ) {
      // Go to login page
      // const tokenExpired = localStorage.getItem(TOKEN_REAL_EXPIRED_DATE);
      // const tokenExpiredDate = new Date(tokenExpired);
      // if (tokenExpiredDate.getTime() < new Date().getTime()) {
      // localStorage.clear();
      // deleteAllCookies();
      // window.location.href = "/sign-in";
      // }
    }
    return Promise.reject(error);
  }
);

export default httpClient;
